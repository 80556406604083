<template>
  <div>
    <!-- ADVERTISER -->
    <div>
      <div v-if="loader && !advertiser" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <div v-else-if="user && advertiser">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h1 class="highlighted-color1">
              <span>{{ advertiser.name }}</span>
            </h1>
          </div>
          <div>
            <router-link :to="{ name: 'advertisers.index' }" class="btn btn-primary">{{ $t('back') }}</router-link>
          </div>
        </div>
        <!-- MESSAGES -->
        <div>
          <div v-if="advertiser.status === 'ERROR' || advertiser.status === 'INACTIVE'">
            <div
              class="mb-4 error-message-card text-white font-size-small p-3"
              v-if="advertiser.status === 'ERROR'"
            >
              <p
                class="mb-0"
              >
                {{ $t('error_creating_advertiser') }}
                <span>
                  <RetryAdvertiserCreationOnPlatformButton
                    :advertiserId="advertiser.id"
                    @retry-successful="getAdvertiser"
                    @retry-error="handleRetryError"
                    color="white"
                  />
                </span>
              </p>
            </div>
          </div>
        </div> <!-- END MESSAGES -->
        <!-- ADVERTISER CARD -->
        <div class="card mb-4">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex align-items-center mb-3">
                  <div class="advertiser-logo me-3" :style="{
                    backgroundImage: advertiser.logo ? `url('${advertiser.logo}')` : false
                  }"></div>
                  <h2 class="mb-0">
                    {{ advertiser.name }}
                  </h2>
                </div>
                <h3 class="font-size-regular">
                  <strong>{{ $t('country') }}:</strong> {{ $t(`countries_names.${advertiser.country}`) }}<br/>
                  <strong>{{ $t('currency') }}:</strong> {{ advertiser.currency | formatCurrencyType }}<br/>
                  <strong>{{ $t('available_balance') }}:</strong> {{ advertiser.available_amount / 100 | formatMoney(advertiser.currency) }}<br/>
                  <strong>{{ $t('allocated_balance') }}:</strong> {{ advertiser.allocated_amount / 100 | formatMoney(advertiser.currency) }}<br/>
                  <strong>{{ $t('outstanding_balance') }}:</strong> {{ advertiser.pending_amount / 100 | formatMoney(advertiser.currency) }}
                </h3>
                <p class="font-size-regular">
                  <strong>{{ $t('invested') }}:</strong> {{ advertiser.spending / 100 | formatMoney(advertiser.currency) }}<br/>
                  <strong>{{ $t('impressions') }}:</strong> {{ advertiser.impressions | formatNumber }}<br/>
                  <strong>{{ $t('ctr') }}:</strong> {{ advertiser.ctr | formatNumber }}%
                </p>
                <p>
                  {{ advertiser.website }}<br/>
                  {{ $t('categories.' + advertiser.category.slug) }}<br/>
                  {{ $t('registration_date') }}: {{ advertiser.created_at | formatDate }}<br/>
                  {{ $t('billing_profile') }}: {{ advertiser.billing_profile | formatBillingProfile }}<br/>
                  {{ $t('status') }}: {{ advertiser.status | formatStatus }}
                </p>
              </div>
              <div class="text-lg-end">
                <div class="mb-3">
                  <Button
                    class="btn-sm btn-primary"
                    v-b-modal.upsert-advertiser-modal
                  >
                    {{ $t('edit_advertiser') }}
                  </Button>
                </div>
                <div class="mb-3">
                  <Button
                    class="btn-sm btn-primary"
                    v-if="advertiser.status === 'ACTIVE'"
                    v-b-modal.destroy-advertiser-modal
                  >
                    {{ $t('disable_advertiser') }}
                  </Button>
                  <Button
                    class="btn-sm btn-primary"
                    v-else-if="advertiser.status === 'INACTIVE'"
                    v-b-modal.activate-advertiser-modal
                  >
                    {{ $t('activate_advertiser') }}
                  </Button>
                </div>
                <!-- COUPON -->
                <div>
                  <Button
                    class="btn-color1 btn-sm"
                    v-b-modal.add-coupon-modal
                    v-if="!advertiser.coupon"
                  >
                    {{ $t('add_coupon') }}
                  </Button>
                  <template v-else>
                    <div class="d-flex align-items-center">
                      <span class="fw-bold me-2">{{ $t('active_coupon') }}:</span>
                      <span class="bg-color2 text-white px-3 py-2 font-size-xSmall">{{ advertiser.coupon.code }}</span>
                      <Button
                        class="btn-xs btn-circle btn-danger font-size-small p-0 ms-2"
                        v-b-modal.destroy-coupon-modal
                        v-b-tooltip.hover :title="$t('logout')"
                      >
                        <i class="fa fa-trash"></i>
                      </Button>
                    </div>
                    <RemoveCouponModal
                      :advertiser="advertiser"
                      :coupon="advertiser.coupon"
                      @coupon-destroyed="handleDestroyedCoupon"
                    />
                  </template>
                </div> <!-- END COUPON -->
              </div>
            </div>
          </div>
          <UpsertAdvertiserModal
            :advertiser="advertiser"
            @advertiser-updated="getAdvertiser"
          />
          <DestroyAdvertiserModal
            :advertiser="advertiser"
            @advertiser-destroyed="getAdvertiser"
          />
          <ActivateAdvertiserModal
            :advertiser="advertiser"
            @advertiser-activated="getAdvertiser"
          />
          <AddCouponModal
            :advertiser="advertiser"
            @coupon-added="handleAddedCoupon"
          />
        </div> <!-- END ADVERTISER CARD -->

        <div
          class="mb-4 error-message-card text-white font-size-small p-3"
          v-if="advertiser.status === 'INACTIVE'"
        >
          <p class="mb-0">
            {{ $t('change_advertiser_first_activate') }}
          </p>
        </div>

        <!-- TABS -->
        <b-tabs content-class="mt-4" nav-class="default-tabs" v-model="tabIndex">
          <!-- ORDERS TAB -->
          <b-tab :title="$t('payments')">
            <!-- ORDERS -->
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h2 class="highlighted-color1">
                    <span>{{ $t('payment_history') }}</span>
                  </h2>
                </div>
                <div>
                  <CreateOrderButton
                    v-if="user"
                    :advertiser="advertiser"
                    @order-created="handleOrderCreated"
                  />
                </div>
              </div>
              <div class="mt-4">
                <OrderListTable
                  :advertiser="advertiser"
                  :reload="reloadOrders"
                />
              </div>
            </div> <!-- END ORDERS -->
          </b-tab> <!-- END ORDERS TAB -->
          <!-- CAMPAIGNS TAB -->
          <b-tab :title="$t('campaigns')">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 class="highlighted-color1">
                  <span>{{ $t('campaigns') }}</span>
                </h2>
              </div>
              <div>
                <CreateCampaignButton
                  v-if="advertiser.status === 'ACTIVE'"
                />
              </div>
            </div>
            <CampaignListTable
              :advertiserId="advertiser.id"
            />
          </b-tab> <!-- END CAMPAIGNS TAB -->
          <!-- CREATIVES TAB -->
          <b-tab :title="$t('creatives')">
            <div>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <h2 class="highlighted-color1">
                    <span>{{ $t('creatives') }}</span> <span v-b-tooltip.hover :title="tooltips.creatives"><i class="fa fa-info-circle font-size-medium"></i></span>
                  </h2>
                </div>
                <div>
                  <Button
                    class="btn btn-color1"
                    v-b-modal="'create-creative-modal'"
                    v-if="advertiser.status === 'ACTIVE'"
                  >
                    {{ $t('add_creative') }}
                  </Button>
                </div>
              </div>
              <CreateCreativeModal
                :advertiserId="advertiser.id"
                @creative-created="handleCreativeCreated"
              />
              <CreativeListTable
                :advertiserId="advertiser.id"
                :reload="reloadCreatives"
                :showFilters="true"
              />
            </div>
          </b-tab> <!-- END CREATIVES TAB -->
          <!-- TRACKING TAB -->
          <b-tab title="Pixels">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 class="highlighted-color1">
                  <span>{{ $t('pixels') }}</span> <span v-b-tooltip.hover :title="tooltips.pixels"><i class="fa fa-info-circle font-size-medium"></i></span>
                </h2>
              </div>
              <div>
                <Button
                  class="btn btn-color1"
                  v-b-modal="'upsert-pixel-modal'"
                  v-if="advertiser.status === 'ACTIVE'"
                >
                  {{ $t('create_pixel') }}
                </Button>
              </div>
            </div>
            <UpsertPixelModal
              :advertiser="advertiser"
              @pixel-updated="handlePixelUpserted"
              @pixel-created="handlePixelUpserted"
            />
            <PixelListTable
              :advertiserId="advertiser.id"
              :reload="reloadPixels"
            />
          </b-tab> <!-- END TRACKING TAB -->
          <!-- SITE LISTS TAB -->
          <b-tab :title="$t('website_lists')">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 class="highlighted-color1">
                  <span>{{ $t('website_lists') }}</span> <span v-b-tooltip.hover :title="tooltips.site_lists"><i class="fa fa-info-circle font-size-medium"></i></span>
                </h2>
              </div>
              <div>
                <Button
                  class="btn btn-color1"
                  v-b-modal="'upsert-custom-targeting-site-list-modal'"
                  v-if="advertiser.status === 'ACTIVE'"
                >
                  {{ $t('create_list') }}
                </Button>
              </div>
            </div>
            <div>
              <CustomTargetingListTable
                :advertiserId="advertiser.id"
                :types="['SITE_LIST']"
                :reload="reloadSiteLists"
              />
            </div>
            <UpsertCustomTargetingModal
              :advertiser="advertiser"
              type="SITE_LIST"
              modalId="upsert-custom-targeting-site-list-modal"
              @custom-targeting-updated="handleSiteListUpserted"
              @custom-targeting-created="handleSiteListUpserted"
            />
          </b-tab> <!-- END SITE LISTS TAB -->
          <!-- CUSTOM AUDIENCES TAB -->
          <b-tab :title="$t('custom_audiences')">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 class="highlighted-color1">
                  <span>{{ $t('custom_audiences') }}</span> <span v-b-tooltip.hover :title="tooltips.audiences"><i class="fa fa-info-circle font-size-medium"></i></span>
                </h2>
              </div>
              <div>
                <Button
                  class="btn btn-color1"
                  v-b-modal="'upsert-custom-targeting-email-audience-modal'"
                  v-if="advertiser.is_custom_audiences_enabled && advertiser.status === 'ACTIVE'"
                >
                  {{ $t('create_audience') }}
                </Button>
              </div>
            </div>
            <div v-if="!advertiser.is_custom_audiences_enabled">
              <p>{{ $t('custom_audiences_available') }}</p>
            </div>
            <div v-else>
              <CustomTargetingListTable
                :advertiserId="advertiser.id"
                :types="['EMAIL_AUDIENCE']"
                :reload="reloadCustomAudiences"
              />
            </div>
            <UpsertCustomTargetingModal
              :advertiser="advertiser"
              type="EMAIL_AUDIENCE"
              modalId="upsert-custom-targeting-email-audience-modal"
              @custom-targeting-updated="handleCustomAudienceUpserted"
              @custom-targeting-created="handleCustomAudienceUpserted"
              v-if="advertiser.is_custom_audiences_enabled"
            />
          </b-tab> <!-- END CUSTOM AUDIENCES TAB -->
        </b-tabs> <!-- END TABS -->
      </div>
    </div> <!-- END ADVERTISER -->
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import moment from 'moment'
import { mapState } from 'vuex'
import OrdersService from '@/modules/orders/services/orders-service'
import variables from '@/mixins/variables'

import ActivateAdvertiserModal from '@/components/advertisers/ActivateAdvertiserModal'
import AddCouponModal from '@/components/coupons/AddCouponModal'
import Button from '@/components/common/Button/Button'
import CampaignListTable from '@/components/campaigns/CampaignListTable'
import CreateCampaignButton from '@/components/campaigns/CreateCampaignButton'
import CreateCreativeModal from '@/components/creatives/CreateCreativeModal'
import CreateOrderButton from '@/components/orders/CreateOrderButton'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import CustomTargetingListTable from '@/components/custom-targetings/CustomTargetingListTable'
import DestroyAdvertiserModal from '@/components/advertisers/DestroyAdvertiserModal'
import Loader from '@/components/common/Loader/Loader'
import OrderListTable from '@/components/orders/OrderListTable'
import PixelListTable from '@/components/pixels/PixelListTable'
import RemoveCouponModal from '@/components/coupons/RemoveCouponModal'
import RetryAdvertiserCreationOnPlatformButton from '@/components/advertisers/RetryAdvertiserCreationOnPlatformButton'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal'
import UpsertCustomTargetingModal from '@/components/custom-targetings/UpsertCustomTargetingModal'
import UpsertPixelModal from '@/components/pixels/UpsertPixelModal'

export default {
  mixins: [variables],
  components: {
    ActivateAdvertiserModal,
    AddCouponModal,
    Button,
    CampaignListTable,
    CreateCampaignButton,
    CreateCreativeModal,
    CreateOrderButton,
    CreativeListTable,
    CustomTargetingListTable,
    DestroyAdvertiserModal,
    Loader,
    OrderListTable,
    PixelListTable,
    RemoveCouponModal,
    RetryAdvertiserCreationOnPlatformButton,
    UpsertAdvertiserModal,
    UpsertCustomTargetingModal,
    UpsertPixelModal
  },
  data () {
    return {
      advertiser: null,
      filters: {
        status: '',
        start: '',
        end: ''
      },
      loader: false,
      orders: [],
      ordersLoader: false,
      reloadCreatives: false,
      reloadCustomAudiences: false,
      reloadOrders: false,
      reloadPixels: false,
      reloadSiteLists: false,
      searchCreative: '',
      selectedOrder: '',
      tabIndex: 0
    }
  },
  metaInfo () {
    return {
      title: this.advertiser ? this.advertiser.name : ''
    }
  },
  created () {
    this.filters.start = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(60, 'days').format()
    this.filters.end = moment({ hour: 0, minute: 0, seconds: 0 }).add(60, 'days').format()
    this.setTabIndex()
    this.getAdvertiser()
  },
  watch: {
    'filters.start': function (newValue, oldValue) {
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV) && this.user) {
        this.orders = []
        this.getOrders()
      }
    },
    'filters.end': function (newValue, oldValue) {
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV) && this.user) {
        this.orders = []
        this.getOrders()
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Payment status filter
     */
    filterPaymentStatus () {
      const allowed = ['PAID', 'PENDING', 'PROCESSING', 'WAITING_PAYMENT', 'REFUSED', 'EXPIRED']
      const array = this.paymentStatus.filter(item => {
        return allowed.includes(item.key)
      })
      return array.sort((a, b) => a.value.localeCompare(b.value))
    }
  },
  methods: {
    /**
     * Get advertiser
     */
    async getAdvertiser () {
      this.loader = true
      try {
        const advertiser = await AdvertisersService.getAdvertiser(this.$route.params.id)
        this.advertiser = advertiser
        this.getOrders()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    async getOrders () {
      this.ordersLoader = true
      try {
        const orders = await OrdersService.getOrders({
          advertiserId: this.advertiser.id,
          start_date: moment(this.filters.start).format('YYYY-MM-DD'),
          end_date: moment(this.filters.end).format('YYYY-MM-DD'),
          status: this.filters.status
        })
        this.orders = orders.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.ordersLoader = false
    },
    /**
     * Handle after coupon was added
     */
    handleAddedCoupon () {
      this.orders = []
      this.getAdvertiser()
    },
    /**
     * Handle creative created
     */
    handleCreativeCreated () {
      this.reloadCreatives = true
      setTimeout(() => {
        this.reloadCreatives = false
      }, 700)
    },
    /**
     * Handle custom audience upserted
     */
    handleCustomAudienceUpserted () {
      this.reloadCustomAudiences = true
      setTimeout(() => {
        this.reloadCustomAudiences = false
      }, 700)
    },
    /**
     * Handle destroyed coupon
     */
    handleDestroyedCoupon () {
      this.orders = []
      this.getAdvertiser()
    },
    /**
     * Handle order created
     */
    handleOrderCreated () {
      this.reloadOrders = true
      setTimeout(() => {
        this.reloadOrders = false
      }, 700)
    },
    /**
     * Handle pixel upserted
     */
    handlePixelUpserted () {
      this.reloadPixels = true
      setTimeout(() => {
        this.reloadPixels = false
      }, 700)
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Handle site list upserted
     */
    handleSiteListUpserted () {
      this.reloadSiteLists = true
      setTimeout(() => {
        this.reloadSiteLists = false
      }, 700)
    },
    /**
     * Set tab index
     */
    setTabIndex () {
      const tab = this.$route.query.tab
      let index = 0
      switch (tab) {
        case 'payments':
          index = 0
          break
        case 'campaigns':
          index = 1
          break
        case 'creatives':
          index = 2
          break
        case 'pixels':
          index = 3
          break
        case 'site-lists':
          index = 4
          break
        case 'custom-audiences':
          index = 5
          break
        default:
          break
      }
      this.tabIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.advertiser-logo {
  width: 50px;
  height: 50px;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
}
</style>
